:root {
  --color-primary: #ff1343;
  --color-primary-darker: #ff5420;
  --color-bg: #fff;
  --color-bg-dark: #f9f9f9;
  --color-bg-darker: #eee;
  --color-bg-darkest: #ccc;
  --color-black: #222;
  --color-text: #444;
  --gradient-primary: linear-gradient(45deg, var(--color-primary), var(--color-primary-darker));
  --box-shadow-standard: #3c40430d 0px 1px 2px 2px;
  --border-radius: .25rem;
  --border-radius-large: .5rem;
  --transition-duration-snappy: .2s;
  --max-page-width: 80rem;
}

@view-transition {
  navigation: auto;
}

* {
  box-sizing: content-box;
}

body, input, button {
  color: var(--color-text);
  font-family: Helvetica, Verdana, Arial, sans-serif;
}

body {
  background-color: var(--color-black);
  margin: 0;
  position: relative;
  overflow-x: hidden;
}

p {
  margin: 0;
}

.header, .footer {
  background: var(--color-black);
  margin-bottom: 15vw;
  padding: 5vw;
  font-family: Gill Sans, Helvetica, Verdana, Arial, sans-serif;
  position: relative;
}

.header:before, .header:after, .footer:before, .footer:after {
  content: "";
  border: 10vw solid #0000;
  border-width: 10vw 50vw;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.header:before, .footer:after {
  border-bottom-color: var(--color-black);
  border-right-color: var(--color-black);
  transform: translateY(100%)rotate(180deg);
}

.header:after, .footer:before {
  border-bottom-color: var(--color-primary);
  border-right-color: var(--color-primary);
  transform: translateY(100%)scale(1.5);
}

.header__title, .footer__title {
  text-transform: uppercase;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin: 0;
  position: absolute;
  left: -.9vw;
}

.header__title {
  font-size: 10.9vw;
  top: -2.5vw;
}

.footer__title {
  z-index: 2;
  text-align: right;
  font-size: 9.7vw;
  top: -2.2vw;
  transform: scale(-1);
}

.header__image {
  max-width: 5rem;
}

.footer {
  margin-top: 20vw;
  margin-bottom: 0;
  transform: scale(-1);
}

.app {
  width: calc(100% - 1rem);
  background-color: var(--color-bg-dark);
  padding: 0 .5rem 2rem;
}

.products {
  max-width: var(--max-page-width);
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: grid;
}

.product {
  z-index: 2;
  background-color: var(--color-bg-dark);
  border-radius: var(--border-radius-large);
  flex-direction: column-reverse;
  justify-content: flex-end;
  display: flex;
  position: relative;
  overflow: hidden;
}

.product:nth-of-type(2n) {
  transform: translateY(2rem);
}

.product-detail {
  padding: 10vw 0;
}

.product--detail {
  max-width: var(--max-page-width);
  margin: 0 auto;
}

.product--detail .product__description {
  display: block;
}

.product--detail .product__image {
  aspect-ratio: 5 / 4;
}

.product--detail .product__name {
  font-size: 3rem;
}

.product--detail .product__brand {
  font-size: 2.4rem;
}

.product--detail .product__price {
  align-self: flex-end;
  font-size: 4rem;
}

.product__details {
  flex-direction: column;
  padding: 1rem;
  display: flex;
}

.product__name {
  letter-spacing: .2rem;
  font-size: 1.5rem;
}

.product__brand {
  letter-spacing: .2rem;
  font-size: 1.2rem;
  font-weight: 200;
}

.product__price {
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 2rem;
}

.product__link {
  cursor: pointer;
  font-size: 0;
  position: absolute;
  inset: 0;
}

.product__description {
  letter-spacing: .2rem;
  margin: 2rem 0;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
  display: none;
}

.product__button {
  border-radius: var(--border-radius);
  color: var(--color-bg);
  background: var(--gradient-primary);
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  padding: 1rem;
  font-size: 1.5rem;
  display: inline;
}

.product__image-wrapper {
  border-radius: var(--border-radius);
}

.product__image {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  transition: transform var(--transition-duration-snappy) ease-in-out;
}

@media (min-width: 768px) {
  .header__image {
    max-width: 25rem;
  }

  .app {
    padding: 0 .5rem 5rem;
  }

  .products {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 3rem;
  }

  .product--detail {
    max-width: unset;
  }

  .product--detail .product__image {
    max-height: 60vh;
    margin-bottom: 5rem;
  }

  .product--detail .product__details {
    max-width: var(--max-page-width);
    margin: 0 auto;
  }

  .product--detail .product__price {
    align-self: flex-end;
    font-size: 10rem;
  }

  .product__description {
    letter-spacing: .25rem;
    font-size: 2rem;
    line-height: 3rem;
  }
}

/*# sourceMappingURL=aeroblast-max-novaglide.7b1e35d8.css.map */
